import Link from "next/link";
import {t} from "i18next";

interface SignInPopupProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function SignInPopup({ isOpen, setIsOpen }: SignInPopupProps) {
  return (
    <>
      <div className="fixed z-10 inset-0 overflow-y-auto" {...(isOpen ? { "aria-hidden": "false" } : { hidden: true })}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            aria-hidden="true"
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    {t("sign-in-popup.title")}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                    {t("sign-in-popup.description")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <Link href="/sign-in" className="relative">
              <div className="absolute -inset-2">
                    <div className="w-1/2 h-1/2 mt-2 mx-auto blur-lg filter" style={{ background: "linear-gradient(90deg, #44ff9a -0.55%,  #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)" }}></div>
                  </div>
                <button
                  type="button"
                  className="relative w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t("sign-in-popup.submit")}
                </button>
              </Link>
              <button
                onClick={() => setIsOpen(false)}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {t("sign-in-popup.cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}