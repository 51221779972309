export const restrictedWords = [
    'sikmek', 
    'sikiş', 
    'yarrak', 
    'amcık', 
    'amk', 
    'amq', 
    'amına', 
    'orospu', 
    'yarak', 
    'yarrak', 
    'yarram', 
    'yavşak', 
    'yavşaq', 
    'taşak', 
    'taşşak', 
    'daşşak',
    'Fuck',
    'Shit',
    'Bitch',
    'Asshole',
    'Bastard',
    'Cunt',
    'Dick',
    'Pussy',
    'Twat',
    'Wanker',
    'Motherfucker',
    'Cock',
    'Arsehole',
    'Bollocks',
    'Prick',
    'Dipshit',
    'Douchebag',
    'Twit',
    'Jerk',
    'Idiot',
    'Amına koyayım',
    'Orospu çocuğu',
    'Götveren',
    'Piç',
    'Yarak',
    'Şerefsiz',
    'Gavat',
    'Şıllık',
    'Taşşak',
    'Kaltak',
    'Siktir git',
    'Pezevenk',
    'Arschloch',
    'Wichser',
    'Fotze',
    'Hurensohn',
    'Scheiße',
    'Fick',
    'Drecksau',
    'Missgeburt',
    'Nutte',
    'Verpiss',
    'Vollidiot',
    'Blödmann',
    'Schwanz',
    'Teufelsbraten',
    'Saftsack']
