import { restrictedWords } from "../restrictedWords";
import {
  doc,
  getDoc,
  collection,
  setDoc,
  serverTimestamp,
  Timestamp,
  onSnapshot,
  runTransaction,
  Transaction,
  DocumentReference,
} from "firebase/firestore";
import { firestore } from "../initFirebase";
import { User } from "firebase/auth";
import Stripe from "stripe";

const DAILY_LIMIT = 2;

const stripe = new Stripe(process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY as string, {
  apiVersion: "2022-11-15",
});

export const getProducts = async (currency: string) => {
  const products = await stripe.products.search({
    query: 'active:"true" AND name~"unlimited"',
  });

  const prices = await stripe.prices.search({
    query: `active:"true" AND currency:"${currency}"`,
    expand: ["data.currency_options"],
  });

  const productsWithPrices = products.data.map((product) => {
    const productPrices = prices.data.filter(
      (price) => price.product === product.id
    );
    return { ...product, prices: productPrices };
  });

  return productsWithPrices;
};

export const createCheckoutSession = async (
  priceId: string,
  email: string,
  type: string
) => {
  const mode: "payment" | "subscription" =
    type === "recurring" ? "subscription" : "payment";
  const sessionParams: Stripe.Checkout.SessionCreateParams = {
    line_items: [{ price: priceId, quantity: 1 }],
    mode,
    success_url: "https://www.professionalizeitto.me/dashboard",
    cancel_url: "https://www.professionalizeitto.me/pricing",
  };
  if (email) {
    sessionParams["customer_email"] = email;
  }

  const session = await stripe.checkout.sessions.create(sessionParams);
  return session;
};

const getCustomerFromStripe = async (email: string) => {
  const customers = await stripe.customers.search({
    query: `email:"${email}"`,
  });
  return customers.data[0];
};

export const createPortalSession = async (email: string) => {
  const customer = await getCustomerFromStripe(email);
  const session = await stripe.billingPortal.sessions.create({
    customer: customer.id,
    return_url: "https://www.professionalizeitto.me/dashboard",
  });
  return session.url;
};

const isExpired = (seconds?: number) => {
  if (!seconds) return true;
  const now = Math.floor(new Date().getTime() / 1000);
  return now > seconds;
};

export function isMessageContainsRestrictedWords(message: string) {
  const messageWords = message.split(" ");
  const restrictedWordsArray = restrictedWords;

  for (const word of messageWords) {
    if (restrictedWordsArray.includes(word.toLowerCase())) {
      return true;
    }
  }
  return false;
}

export async function isUnlimitedUser(email: string) {
  const docRef = doc(firestore, "users", email);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData = docSnap.data();
    return (
      (docData.isDailyUnlimited ||
        docData.isMonthlyUnlimited ||
        docData.isWeeklyUnlimited) &&
      !isExpired(docData.expiredAt?.seconds)
    );
  }
  return false;
}

export async function isDailyLimitExceeded(ip: string) {
  const docRef = doc(firestore, "messages", ip);
  const docSnap = await getDoc(docRef);
  const nowMilliseconds = new Date().getTime();

  if (docSnap.exists()) {
    const docData = docSnap.data();
    const date = new Date(docData.updated.seconds * 1000);
    const now = new Date();

    if (docData.dailyLimit >= DAILY_LIMIT && date.getDate() === now.getDate()) {
      return true;
    } else {
      const dailyLimit =
        date.getDate() !== now.getDate() ? 1 : docData.dailyLimit + 1;
      await setDoc(docRef, {
        dailyLimit,
        updated: serverTimestamp(),
        expiredAt:
          docData.expiredAt || Timestamp.fromMillis(nowMilliseconds - 86400),
      });
    }
  } else {
    await setDoc(docRef, {
      dailyLimit: 1,
      updated: serverTimestamp(),
      expiredAt: Timestamp.fromMillis(nowMilliseconds - 86400),
    });
  }

  return false;
}

export const initRealTimeListener = (
  ip: string,
  callback: (limit: number) => void
) => {
  const docRef = doc(firestore, "messages", ip);
  return onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      callback(data?.dailyLimit || DAILY_LIMIT);
    }
  });
};

export const updateDocument = async (
  docRef: DocumentReference<any>,
  data: { dailyLimit: number; expiredAt: any }
) => {
  await setDoc(docRef, { ...data, updated: serverTimestamp() });
};

export const getDailyLimit = async (ip: string) => {
  const docRef = doc(firestore, "messages", ip);
  const docSnap = await getDoc(docRef);
  const now = new Date();
  const defaultData = {
    dailyLimit: 0,
    expiredAt: Timestamp.fromMillis(now.getTime() - 86400),
  };

  if (docSnap.exists()) {
    const docData = docSnap.data();
    const updatedDate = new Date(docData.updated.seconds * 1000);
    const isSameDay = updatedDate.getDate() === now.getDate();
    const currentDailyLimit = isSameDay ? docData.dailyLimit : 0;
    const remainingLimit = DAILY_LIMIT - currentDailyLimit;

    await updateDocument(docRef, {
      dailyLimit: currentDailyLimit,
      expiredAt: docData.expiredAt || defaultData.expiredAt,
    });
    return Math.max(remainingLimit, 0);
  }

  await updateDocument(docRef, defaultData);
  return DAILY_LIMIT;
};

export const sendData = async (
  prompt: any,
  answer: any,
  language: any,
  vibe: any,
  messageType: any,
  length: any,
  ip: string,
  user?: User
) => {
  const now = new Date();
  const date = now.toISOString();
  const nowMilliseconds = now.getTime();
  const promptsRef = collection(firestore, `messages/${ip}/prompts`);

  if (user?.email) {
    const userRef = collection(firestore, "users", user.email, "prompts");
    const docRef = doc(firestore, "users", user.email);
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();

    if (!docData || !docData.expiredAt || !docData.updated) {
      await setDoc(docRef, {
        expiredAt: Timestamp.fromMillis(nowMilliseconds - 86400),
        updated: serverTimestamp(),
      });
    }

    try {
      await setDoc(docRef, {
        expiredAt:
          docData?.expiredAt || Timestamp.fromMillis(nowMilliseconds - 86400),
        updated: serverTimestamp(),
        isMonthlyUnlimited: docData?.isMonthlyUnlimited || false,
        isWeeklyUnlimited: docData?.isWeeklyUnlimited || false,
        isDailyUnlimited: docData?.isDailyUnlimited || false,
      });
      await setDoc(doc(userRef, date), {
        prompt,
        answer,
        language,
        vibe,
        messageType,
        length,
        createdAt: serverTimestamp(),
      });
    } catch (error) {
      console.log(error);
    }
    return;
  }

  try {
    const docRef = doc(firestore, `messages/${ip}/`);
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();

    if (
      !docData ||
      ((!docData.dailyLimit || docData.dailyLimit === 0) &&
        (docData.isDailyUnlimited ||
          docData.isMonthlyUnlimited ||
          docData.isWeeklyUnlimited) &&
        isExpired(docData.expiredAt?.seconds))
    ) {
      await setDoc(docRef, {
        dailyLimit: 1,
        expiredAt: Timestamp.fromMillis(nowMilliseconds - 86400),
        updated: serverTimestamp(),
        isDailyUnlimited: false,
        isMonthlyUnlimited: false,
        isWeeklyUnlimited: false,
      });
    }

    await setDoc(doc(promptsRef, date), {
      prompt,
      answer,
      language,
      vibe,
      messageType,
      length,
      createdAt: serverTimestamp(),
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendFormula = async (
  prompt: any,
  formula: any,
  ip: string,
  user?: User
) => {
  const now = new Date();
  const date = now.toISOString();
  const nowMilliseconds = now.getTime();
  const formulasRef = collection(firestore, `messages/${ip}/formulas`);

  if (user?.email) {
    const userRef = collection(firestore, "users", user.email, "formulas");
    const docRef = doc(firestore, "users", user.email);
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();

    if (!docData || !docData.expiredAt || !docData.updated) {
      await setDoc(docRef, {
        expiredAt: Timestamp.fromMillis(nowMilliseconds - 86400),
        updated: serverTimestamp(),
      });
    }

    try {
      await setDoc(docRef, {
        expiredAt:
          docData?.expiredAt || Timestamp.fromMillis(nowMilliseconds - 86400),
        updated: serverTimestamp(),
        isMonthlyUnlimited: docData?.isMonthlyUnlimited || false,
        isWeeklyUnlimited: docData?.isWeeklyUnlimited || false,
        isDailyUnlimited: docData?.isDailyUnlimited || false,
      });
      await setDoc(doc(userRef, date), {
        prompt,
        formula,
        createdAt: serverTimestamp(),
      });
    } catch (error) {
      console.log(error);
    }
    return;
  }

  try {
    const docRef = doc(firestore, `messages/${ip}/`);
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();

    if (
      !docData ||
      ((!docData.dailyLimit || docData.dailyLimit === 0) &&
        (docData.isDailyUnlimited ||
          docData.isMonthlyUnlimited ||
          docData.isWeeklyUnlimited) &&
        isExpired(docData.expiredAt?.seconds))
    ) {
      await setDoc(docRef, {
        dailyLimit: 1,
        expiredAt: Timestamp.fromMillis(nowMilliseconds - 86400),
        updated: serverTimestamp(),
        isDailyUnlimited: false,
        isMonthlyUnlimited: false,
        isWeeklyUnlimited: false,
      });
    }

    await setDoc(doc(formulasRef, date), {
      prompt,
      formula,
      createdAt: serverTimestamp(),
    });
  } catch (error) {
    console.log(error);
  }
};

export const createOrUpdateRateLimits = async (ip: string) => {
  const docRef = doc(firestore, "rateLimits", ip);
  const docSnap = await getDoc(docRef);
  const now = new Date();

  if (docSnap.exists()) {
    const docData = docSnap.data();
    const updatedAt = docData.updatedAt.toDate();

    if (
      updatedAt.getDate() !== now.getDate() ||
      updatedAt.getMonth() !== now.getMonth() ||
      updatedAt.getFullYear() !== now.getFullYear()
    ) {
      await setDoc(docRef, {
        ...docData,
        transcriptionLimit: 0,
        coverLetterLimit: 0,
        messageLimit: 0,
        formulaLimit: 0,
        updatedAt: serverTimestamp(),
      });
    }
  } else {
    await setDoc(docRef, {
      coverLetterLimit: 0,
      messageLimit: 0,
      formulaLimit: 0,
      transcriptionLimit: 0,
      updatedAt: serverTimestamp(),
    });
  }
};

export const isTranscriptionLimitExceeded = async (ip: string) => {
  const docRef = doc(firestore, "rateLimits", ip);
  const docSnap = await getDoc(docRef);
  const now = new Date();

  if (docSnap.exists()) {
    const docData = docSnap.data();
    const updatedAt = docData.updatedAt.toDate();

    if (
      updatedAt.getDate() !== now.getDate() ||
      updatedAt.getMonth() !== now.getMonth() ||
      updatedAt.getFullYear() !== now.getFullYear()
    ) {
      await setDoc(docRef, {
        ...docData,
        transcriptionLimit: 1,
        updatedAt: serverTimestamp(),
      });
      return false;
    }

    if (docData.transcriptionLimit >= 2) {
      return true;
    }
  } else {
    await createOrUpdateRateLimits(ip);
    await increaseTranscriptionRateLimit(ip);
  }

  await increaseTranscriptionRateLimit(ip);
  return false;
};

export const increaseTranscriptionRateLimit = async (ip: string) => {
  const docRef = doc(firestore, "rateLimits", ip);

  await runTransaction(firestore, async (transaction: Transaction) => {
    const docSnap = await transaction.get(docRef);
    const now = new Date();

    if (docSnap.exists()) {
      const docData = docSnap.data()!;
      const updatedAt = docData.updatedAt.toDate();

      if (
        updatedAt.getDate() !== now.getDate() ||
        updatedAt.getMonth() !== now.getMonth() ||
        updatedAt.getFullYear() !== now.getFullYear()
      ) {
        transaction.set(docRef, {
          ...docData,
          transcriptionLimit: 1,
          updatedAt: serverTimestamp(),
        });
      } else {
        transaction.set(docRef, {
          ...docData,
          transcriptionLimit: docData.transcriptionLimit + 1,
          updatedAt: serverTimestamp(),
        });
      }
    } else {
      transaction.set(docRef, {
        coverLetterLimit: 0,
        messageLimit: 0,
        formulaLimit: 0,
        transcriptionLimit: 1,
        updatedAt: serverTimestamp(),
      });
    }
  });
};
